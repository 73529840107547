import { useRouter } from 'next/router';
import React from 'react';
import styles from './NotFound.module.scss';

const NotFound = () => {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <img
        loading="lazy"
        className={styles.image}
        src="/webp/404.webp"
        alt="Page Not Found"
      />
      <span className={styles.title}>Page Not Found</span>
      <span className={styles.description}>
        Looks like this page doesn&apos;t exist. Maybe the link is broken.
      </span>
      <button
        onClick={() => {
          router.replace('/');
        }}
        className={styles.button}
      >
        <p>Take me home</p>
      </button>
    </div>
  );
};

export default NotFound;
